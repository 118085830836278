<template>
    <h2
        v-if="title"
        class="scale-3 scale-3--bold-uppercase spacer-30"
        :id="anchorTitle"
    >
        {{ title }}
    </h2>
    <LoadMore
        @next="loadNext"
        @previous="loadPrevious"
        :name="loadMore?.name"
        :page="loadMore?.page"
        :size="loadMore?.size"
        :pages="loadMore?.pages"
        :total="loadMore?.total"
        :prev-url="prevUrl"
        :next-url="nextUrl"
        :initial="{
            from: loadMore?.initial.from,
            to: loadMore?.initial.to,
        }"
    >
        <template #previous>
            <div class="grid">
                <MagazineTeaser
                    v-for="item in previous"
                    :teaser="item"
                    class="item mb-5"
                />
            </div>
        </template>
        <template #default>
            <div
                class="grid"
                :class="{
                    'grid--previous': previous.length > 0,
                    'grid--next': next.length > 0,
                }"
            >
                <MagazineTeaser
                    v-for="item in current"
                    :teaser="item"
                    class="item"
                />
            </div>
        </template>
        <template #next>
            <div class="grid">
                <MagazineTeaser
                    v-for="item in next"
                    :teaser="item"
                    class="item"
                />
            </div>
        </template>
    </LoadMore>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';
const props = defineProps({
    title: {
        type: String,
    },
    loadMore: {
        type: Object,
    },
    items: {
        type: Array,
        required: true,
        default: () => [],
    },
});
const { t } = useI18n();

const currentPage = ref(parseInt(props.loadMore?.page));
const startFromPage = ref(props.loadMore?.page);
const previewPage = ref(
    props.loadMore?.page > 1 ? props.loadMore?.page - 1 : null,
);
const urlPage = ref(props.loadMore?.page);

const previous = computed(() => getPrevious(props.items, props.loadMore?.page));
const current = computed(() => getCurrent(props.items, currentPage));
const next = computed(() => getNext(props.items, props.loadMore?.page));
const route = useRoute();

const prevUrl = computed(() => {
    const searchParams = new URLSearchParams(route.params.search);
    searchParams.set('page', urlPage.value - 1);
    return `${route.path}?${searchParams.toString()}`;
});

const nextUrl = computed(() => {
    const searchParams = new URLSearchParams(route.params.search);
    searchParams.set('page', currentPage.value + 1);
    return `${route.path}?${searchParams.toString()}`;
});

function getPrevious(items) {
    if (!previewPage.value) return [];
    return items.slice(previewPage.value * 3, startFromPage.value * 3);
}

function getCurrent(items, page) {
    if (page.value === 1) {
        return items.slice(0, props.loadMore?.size);
    } else {
        return items.slice(
            (startFromPage.value - 1) * props.loadMore?.size,
            page.value * props.loadMore?.size,
        );
    }
}

function getNext(items, page) {
    const data = items.slice(
        page * props.loadMore?.size,
        (page + 1) * props.loadMore?.size,
    );
    return data;
}

function loadNext() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', currentPage.value + 1);
    window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${searchParams.toString()}`,
    );

    currentPage.value = currentPage.value + 1;
}
function loadPrevious() {
    if (previewPage.value !== 1) {
        previewPage.value--;
        startFromPage.value--;
    } else {
        previewPage.value = null;
        startFromPage.value = 1;
    }
    urlPage.value = startFromPage.value;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', startFromPage.value);
    window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${searchParams.toString()}`,
    );
}

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 40px;
    row-gap: clamp(20px, 1.39vw + 15.56px, 40px);
    margin: 0;

    @include media-breakpoint-down(1200) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(800) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &--previous {
        margin-top: clamp(20px, 1.39vw + 15.56px, 40px);
    }

    &--next {
        margin-bottom: clamp(20px, 1.39vw + 15.56px, 40px);
    }
}

.item {
    height: clamp(425px, 16.32vw + 372.78px, 660px) !important;
    width: 100%;
}
</style>
