<template>
    <div class="row spacer-navigation">
        <div class="col-12">
            <h1
                class="scale-6"
                :class="{
                    category__title: page?.description,
                    'spacer-40': !page?.description,
                }"
                :id="anchorTitle"
            >
                {{ displayTitle }}
            </h1>
            <p
                v-if="page?.description"
                class="scale-2 scale-2--lead spacer-80-60 category__description"
            >
                {{ page?.description }}
            </p>
            <h2 class="scale-1 scale-1--bold-uppercase spacer-25-20">
                {{ page?.title }} {{ t('magazine.category.browse') }}
            </h2>
            <SearchFakeInput
                :q="displayQ()"
                class="spacer-80-60 search-input"
            />
        </div>
    </div>
    <div v-if="page?.body?.length > 0" class="spacer-140-100">
        <CmsBody :page="page" />
    </div>
    <div class="row spacer-footer">
        <div
            v-if="page?.subcategories?.length > 0"
            class="col-12 spacer-180-100"
        >
            <Subcategory
                :title="t('magazine.category.subcategories')"
                :items="page?.subcategories"
            />
        </div>
        <div class="col-12">
            <MagazineGallery
                :items="page?.content"
                :load-more="{
                    name: displayTitle,
                    page: pageQ,
                    size: 12,
                    pages: Math.ceil(page?.content?.length / 12),
                    total: page?.content?.length,
                    preview_size: 12,
                    initial: {
                        from: pageQ,
                        to: pageQ,
                    },
                }"
            />
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useHrefLang } from '~/composables/seo/hreflang';
import { useSearchStore } from '@/stores/search';
import { useAnchorTitle } from '~/composables/useAnchorTitle';
import { computed } from 'vue';
import { cleanMetaString } from '@/utils/cleanMeta';

const { t } = useI18n();

const searchStore = useSearchStore();
const { q, filter } = storeToRefs(searchStore);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');
const route = useRoute();

const pageQ = computed(() => {
    return route?.query?.page || 1;
});

const displayTitle = computed(() => {
    var title = '';

    if (page?.value?.title_alias) {
        title = page?.value?.title_alias;
    } else {
        title = page?.value?.title;
    }

    return title;
});

function displayQ() {
    if (page?.value?.meta?.type === 'cms.MagazineTagPage')
        return page?.value?.title;
    return page?.value?.search?.q + ' ' + q.value;
}

const pageTitle = computed(() => {
    let res = '';
    res = `${displayTitle.value} | LUMA Delikatessen`;
    if (pageQ.value > 1) {
        res = `${t('page')} ${pageQ.value} - ${res}`;
    }
    return cleanMetaString(res);
});

if (!page?.value?.meta?.seo_title) {
    useHead({
        title: pageTitle,
        meta: [
            {
                property: 'og:title',
                content: pageTitle,
            },
        ],
    });
}

if (!page?.value?.meta?.search_description) {
    let description;
    if (page?.value?.meta?.type === 'cms.MagazineTagPage') {
        description =
            pageQ.value > 1
                ? `${t('page')} ${pageQ.value} - ${t(
                      'magazine.tag.search_description',
                      {
                          tag: displayTitle.value,
                      },
                  )}`
                : `${t('magazine.tag.search_description', {
                      tag: displayTitle.value,
                  })}`;
    } else {
        description =
            pageQ.value > 1
                ? `${t('page')} - ${pageQ.value} ${page?.value?.description}`
                : page?.value?.description;
    }
    description = cleanMetaString(description);
    useHead({
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: description,
            },
            {
                property: 'og:description',
                content: description,
            },
        ],
    });
}

useHrefLang(page?.value?.meta?.hreflang);

const anchorTitle = computed(() => useAnchorTitle(displayTitle.value));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.category {
    &__title {
        margin-bottom: 15px;
    }

    &__description {
        margin-top: 15px;
    }
}

.search-input {
    width: 66%;
    &:hover {
        cursor: text;
    }

    @include media-breakpoint-down(560) {
        width: 100%;
    }
}
</style>
